import { createSignal, For, onCleanup, Show } from "solid-js";
import { PhosphorIcon } from "~/widgets/icons";
import { useNavigate } from "@solidjs/router";
import { debounce, throttle } from "@solid-primitives/scheduled";
import { toRupees } from "~/utils/number";
import { CartProduct, useSquidContext } from "~/context/squid_context";
import { ThreeDotLoader } from "~/widgets/button";
import { isMobileDevice } from "~/utils/detectDevice";
import { Spacer } from "~/widgets/spacer";

export const SquidCart = () => {
  const {
    squidDataStore,
    updateQuantity,
    simulateErrorMessage,
    loadingSimulateData,
  } = useSquidContext();

  const [error, setError] = createSignal<string | null>(null);

  const navigator = useNavigate();

  let notesRef: HTMLInputElement | undefined;
  const inputMap = new Map<string, HTMLInputElement>();

  onCleanup(() => {
    setError(null);
  });

  const debouncedUpdateQuantity = debounce(updateQuantity, 300);
  const throttleUpdateQuantity = throttle(updateQuantity, 300);
  const productKey = (props: { product: CartProduct }) => {
    return `${props.product.productId}-${props.product.denomination}`;
  };

  const focusSelectedProduct = (props: { product: CartProduct }) => {
    inputMap
      .get(
        productKey({
          product: props.product,
        })
      )
      ?.focus();
  };

  function PlusIcon() {
    return (
      <svg
        width="20"
        height="21"
        viewBox="0 0 20 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          width="20"
          height="20"
          transform="translate(0 0.5)"
          fill="white"
        />
        <path
          d="M9.99943 6.5V14.5"
          stroke="#7C7C7C"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M14 10.5H6"
          stroke="#7C7C7C"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    );
  }

  function MinusIcon() {
    return (
      <svg
        width="20"
        height="21"
        viewBox="0 0 20 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14 10.5H6"
          stroke="#7C7C7C"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    );
  }

  return (
    <div class="flex h-screen w-full flex-col border-l sm:w-[268px]">
      <div class="sticky top-0 z-10 flex items-center justify-start border-b border-gray-200 bg-whiteBg py-4 pl-2 pr-5 sm:top-[6.6px]">
        <div
          class="absolute flex h-11 w-11 items-center justify-center sm:hidden"
          onClick={() => {
            navigator(-1);
          }}
        >
          <PhosphorIcon name="arrow-left" fontSize={24} />
        </div>
        <h1 class="flex items-center gap-2 pl-11 text-bold text-textDark sm:pl-0">
          Cart{" "}
          <span class="text-medium text-textNormal">
            • {squidDataStore.cart.totalQuantity} added
          </span>
        </h1>
      </div>

      <Show
        when={squidDataStore.cart.totalQuantity}
        fallback={
          <div class="m-auto flex w-32 flex-col items-center justify-center gap-1.5">
            <div class="flex h-14 w-14 items-center justify-center rounded-full bg-baseTertiaryMedium">
              <PhosphorIcon name="shopping-cart-simple" fontSize={32} />
            </div>
            <p class="text-center text-medium text-textDark">
              Products you add will appear here
            </p>
          </div>
        }
      >
        <Show
          when={squidDataStore.cart.totalQuantity > 0}
          fallback={
            <div class="m-auto flex w-32 flex-col items-center justify-center gap-1.5">
              <div class="flex h-14 w-14 items-center justify-center rounded-full bg-baseTertiaryMedium">
                <PhosphorIcon name="shopping-cart-simple" fontSize={32} />
              </div>
              <p class="text-center text-medium text-textDark">
                Products you add will appear here
              </p>
            </div>
          }
        >
          <div class="no-scrollbar z-0 h-4/5 overflow-y-auto">
            <For each={squidDataStore.cart.products}>
              {(product) => (
                <div class="flex items-center justify-between gap-2 p-3">
                  <div class="flex items-center space-x-4">
                    <img
                      src={product.detail.logoUrl}
                      alt={product.detail.brandName}
                      class="h-11 w-11 rounded-full"
                    />
                  </div>
                  <div class="flex-1">
                    <h2 class="text-mediumBold text-textDark">
                      {product.detail.brandName}
                    </h2>
                    <p class="text-f12Bold text-textNormal">
                      ₹{product.denomination}
                    </p>
                  </div>
                  <div class="flex flex-col items-end gap-1">
                    <div class="flex h-6 w-[70px] items-center justify-between rounded-md border border-baseTertiaryDark sm:h-5 sm:w-16">
                      <button
                        type="button"
                        class="text-sm shadow-sm inline-flex size-6 items-center justify-center gap-x-2 rounded-md  font-medium text-black focus:outline-none disabled:pointer-events-none disabled:opacity-50"
                        onClick={() => {
                          if (!isMobileDevice()) {
                            focusSelectedProduct({ product: product });
                          }
                          throttleUpdateQuantity(
                            product.productId,
                            product.denomination,
                            Math.max(product.quantity - 1, 0)
                          );
                        }}
                      >
                        <div class="hidden sm:flex">
                          <PhosphorIcon
                            name="minus"
                            fontSize={10}
                            size="bold"
                            class="text-textNormal"
                          />
                        </div>
                        <div class="h-5 w-5 sm:hidden">{MinusIcon()}</div>
                      </button>
                      <input
                        ref={(el) =>
                          inputMap.set(
                            productKey({
                              product: product,
                            }),
                            el
                          )
                        }
                        class="[&::-webkit-outer-spin-button] :appearance-none w-6 scale-75 border-0 bg-transparent p-0 text-center text-f16Bold text-black hover:underline focus:ring-0 sm:scale-100 sm:text-f12Bold [&::-webkit-inner-spin-button]:appearance-none"
                        type="number"
                        value={product.quantity}
                        onInput={(event) => {
                          const input = event.target as HTMLInputElement;
                          const newQuantity = parseInt(input.value, 10);

                          if (!isNaN(newQuantity) && newQuantity >= 0) {
                            debouncedUpdateQuantity(
                              product.productId,
                              product.denomination,
                              newQuantity
                            );
                          } else if (input.value === "") {
                            debouncedUpdateQuantity(
                              product.productId,
                              product.denomination,
                              0
                            );
                          }
                        }}
                      />
                      <button
                        type="button"
                        class="text-sm shadow-sm inline-flex size-6 items-center justify-center gap-x-2 rounded-md font-medium text-black focus:outline-none disabled:pointer-events-none disabled:opacity-50"
                        onClick={() => {
                          if (!isMobileDevice()) {
                            focusSelectedProduct({ product: product });
                          }
                          throttleUpdateQuantity(
                            product.productId,
                            product.denomination,
                            product.quantity + 1
                          );
                        }}
                      >
                        <div class="hidden sm:flex">
                          <PhosphorIcon
                            name="plus"
                            fontSize={10}
                            size="bold"
                            class="text-textNormal"
                          />
                        </div>
                        <div class="h-5 w-5 sm:hidden">{PlusIcon()}</div>
                      </button>
                    </div>
                    <p class="text-f12Bold text-textDark">
                      Total {toRupees(product.amount)}
                    </p>
                  </div>
                </div>
              )}
            </For>
          </div>
        </Show>

        <div class="sticky bottom-0 z-10 border-t border-gray-200 bg-whiteBg p-3">
          <Show when={simulateErrorMessage()}>
            <div class="mb-2 text-f12 text-errorDark">
              {simulateErrorMessage()}
            </div>
          </Show>
          <div class="mb-2 flex justify-between">
            <span class="text-medium text-textDark">Item total</span>
            <span class="text-mediumBold">
              {toRupees(squidDataStore.cart.totalAmount)}
            </span>
          </div>
          <div class="mb-2 flex justify-between">
            <span class="text-medium text-textDark">Discount</span>
            <span class="text-mediumBold text-successDark">
              -{toRupees(squidDataStore.cart.totalDiscount)}
            </span>
          </div>
          <div class="mb-4 flex justify-between">
            <span class="text-bold text-textDark">
              To pay{" "}
              <span class="text-small text-textNormal">
                (Incl. of all taxes)
              </span>
            </span>
            <span class="text-bold text-textDark">
              {toRupees(
                squidDataStore.cart.totalAmount -
                  squidDataStore.cart.totalDiscount
              )}
            </span>
          </div>
          <button
            onClick={() => {
              if (loadingSimulateData()) {
                return;
              }
              navigator("/checkout");
            }}
            class="w-full rounded-lg bg-basePrimaryDark py-3 font-semibold text-white disabled:bg-baseTertiaryDark disabled:text-textNormal"
            disabled={simulateErrorMessage() != undefined}
          >
            <Show
              when={!loadingSimulateData()}
              fallback={
                <div class="flex items-center justify-center p-2">
                  <ThreeDotLoader />
                </div>
              }
            >
              Continue
            </Show>
          </button>

          {/* <button
            onClick={() => {
              return openModal(() => {
                return (
                  <div class="flex flex-col justify-center">
                    <div class="mb-6  mt-8 flex flex-col items-center justify-center">
                      <div class="mb-4 flex h-20 w-20 items-center justify-center rounded-full bg-[#DAF7E8]">
                        <PhosphorIcon
                          name="shopping-cart-simple"
                          fontSize={48}
                          class="text-successDark"
                          size="light"
                        />
                      </div>
                      <p class="text-f12Bold uppercase text-successDark">
                        Confirm order
                      </p>
                      <p class="w-4/5 text-center text-h3 text-textDark">
                        ₹
                        {procurement.cart.totalAmount -
                          procurement.cart.totalDiscount}{" "}
                        will be debited from your wallet
                      </p>
                      <p class="text-medium text-textNormal">
                        Current balance: ₹{procurement.balance}
                      </p>
                      <Show when={error()}>
                        <div class="text-medium text-errorDark">{error()}</div>
                      </Show>
                    </div>
                    <div class="border-t border-baseTertiaryDark p-4">
                      <button
                        class={`w-full rounded-lg bg-successDark px-4 py-2.5 text-buttonMedium text-white ${initOrderJob() ? "bg-gray-400 opacity-50" : "bg-successDark"}`}
                        onClick={() => {
                          if (initOrderJob()) return;
                          initiateOrder();
                        }}
                      >
                        Confirm & pay
                      </button>
                    </div>
                  </div>
                );
              }, "md:w-[380px]");
            }}
            class="w-full rounded-lg bg-basePrimaryDark py-3 font-semibold text-white"
          >
            Continue
          </button> */}
        </div>
      </Show>
    </div>
  );
};
